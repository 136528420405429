import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.css";
import Button from "@mui/material/Button";
import { Link, useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { withRouter } from "react-router";
import HomeIcon from "@mui/icons-material/Home";
import commonData from "../importanValue";
import OneSignal from "react-onesignal";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceWalletIcon from "@mui/icons-material/CurrencyRupee";
if (localStorage.getItem("user") === "1") {
  {
    OneSignal.sendTag("user", "admin");
  }
}
const Header = (props) => {
  const [menu, openMenu] = React.useState(false);
  const token = Cookie.get("jwt_token");
  const history = useHistory();
  // console.log(props);
  const logoutUser = (props) => {
    Cookie.remove("jwt_token");
    localStorage.removeItem("num");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("uid");
    localStorage.removeItem("name");
    history.replace("/login");
  };
  const phoneNum = localStorage.getItem("userDetailsApp");
  return (
    <div className="header-container">
      {/* <p className="heading-para telangana">తెలంగాణ </p>
      <h1 className="heading-title">నవచైతన్య కాంపిటీషన్స్</h1>
      <p className="heading-para">చింతలపూడి , ఏలూరు జిల్లా</p> */}
      <div>
        <img
          src="https://blogger.googleusercontent.com/img/a/AVvXsEhEKLQDcZq6heVrsBOTJ_eRnFJ79GT_A1ZimzoKj31LI8sT5gXE98LhoHxMlTKLadPI76uCKcDa86BiYJOm-KerO1zLjucYLh9EBr5fFA8RcobxuU6NcRoDGntJk1y3yg1XId5VZcWavrHIlATzeYHBhg5YuN3RhfFvtaCDB99yn-dBGW8hmTm-BEQ3UWPk=s16000"
          alt="app-logo"
          style={{
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          id="app-logo-main"
        />
      </div>
      <nav className="navbar">
        <div className="nav-buttons">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Link to="/" className="linkto">
              <HomeIcon
                className="home-button marg"
                style={{ color: "orange" }}
              />
            </Link>

            {/* <Link to="/user-profile" className="linkto">
              <AccountCircleIcon
                className="home-button marg"
                style={{ marginLeft: 25, color: "orange" }}
              />
            </Link> */}

            {/* <Link to="/user/user-wallet" className="linkto">
              <p className="rupee-icon">
                ₹{" "}
                <span className="rupee-icon-span">
                  {localStorage.getItem("userWallet") === undefined
                    ? 0
                    : localStorage.getItem("userWallet")}
                </span>
              </p>
            </Link> */}

            {token !== undefined && (
              <>
                {/* <LogoutIcon
                  className="home-button marg"
                  style={{ marginLeft: 25 }}
                  onClick={logoutUser}
                /> */}
                {/* <hr style={{ margin: 0 }} /> */}
              </>
            )}
          </div>

          <div style={{ display: "flex" }}>
            {/* <p className="menu-parar" onClick={() => openMenu(!menu)}>
              Main Menu
            </p> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <MenuIcon
                className="mobile-icon"
                fontSize="large"
                onClick={() => openMenu(!menu)}
              />
            </div>
          </div>
        </div>
        <div
          className="download-banner"
          style={{
            display: phoneNum
              ? // this.props.location.pathname.split("/")[1] === "attempt-exam"
                "none"
              : "",
            textAlign: "center",
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.navachaitanya.onlineexams&hl=te&gl=US"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              NavaCHAITANYA Exams APP ను డౌన్లోడ్ చేయడానికి ఇక్కడ క్లిక్ చేయండి
            </span>{" "}
          </a>
        </div>

        {menu ? (
          <div>
            {/* {commonData.userMenu.map((eachMen) => <Link to={eachMen.url} className="linkto" target={eachMen.openInNewTab ? "_blank" :""}>
              <Button variant="contained" className="btn header-btns">
                {eachMen.name}
              </Button>
            </Link>)} */}
            {localStorage.getItem("user") === "1" && (
              <a
                href="https://books.navachaitanya.net/orders"
                target="_blank"
                rel="noreferrer"
                className="linkto"
              >
                <Button variant="contained" className="btn header-btns">
                  View Book Orders List
                </Button>
              </a>
            )}
            {commonData.userMenu.map((eachMen) => {
              return eachMen.enabled ? (
                <Link
                  to={eachMen.url}
                  className="linkto"
                  target={eachMen.openInNewTab ? "_blank" : ""}
                >
                  <Button variant="contained" className="btn header-btns">
                    {eachMen.name}
                  </Button>
                </Link>
              ) : null;
            })}

            {localStorage.getItem("user") === "1" && (
              <>
                {commonData.AdminMenu.map((eachMen) => {
                  return eachMen.enabled ? (
                    <Link to={eachMen.url} className="linkto">
                      {/* <div className="deskmenustyle" style={{ marginTop: 15 }}>
                        <div className="deskmenuIcon">➤</div>
                        <p className="deskmenuPara">{eachMen.name}</p>
                      </div> */}
                      <Button variant="contained" className="btn header-btns">
                        {eachMen.name}
                      </Button>
                    </Link>
                  ) : null;
                })}
              </>
            )}
            <a
              href="/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
              className="linkto"
            >
              <Button variant="contained" className="btn header-btns">
                Privacy Policy{" "}
              </Button>
            </a>
            <a
              href="/terms-and-conditions.html"
              target="_blank"
              rel="noreferrer"
              className="linkto"
            >
              <Button variant="contained" className="btn header-btns">
                Terms and Conditions
              </Button>
            </a>
            <a
              href="/refund-policy.html"
              target="_blank"
              rel="noreferrer"
              className="linkto"
            >
              <Button variant="contained" className="btn header-btns">
                Refund Policy{" "}
              </Button>
            </a>
            <Button
              variant="contained"
              className="btn header-btns"
              onClick={logoutUser}
            >
              Logout
            </Button>
          </div>
        ) : null}
      </nav>
    </div>
  );
};

export default withRouter(Header);
