// import React from "react";
// import "./ChatWidget.css"; // Import the CSS file
// import "./styles.css"; // Import the CSS file
// import commonData from "../../importanValue";
// import axios from "axios";
// import Loader from "../Loader";
// import Cookies from "js-cookie";
// import { withRouter } from "react-router-dom";
// import Chat from "./Chat";
// import AdminChat from "./AdminChat";
// import { storeinfo } from "../../redux/reducer";
// import { connect } from "react-redux";

// import {
//   NotificationManager,
//   NotificationContainer,
// } from "react-notifications";
// import "react-notifications/lib/notifications.css";

// class ChatWidget extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: true,
//       isLoading: false,
//       selectedChat: null,
//       selectedChatName: null,
//       isSelf: false,
//       isgroup: false,
//       message: "",
//       chatDetails: [],
//       tschatDetails: [],
//       messages: [],
//       lastId: 0,
//       isOnline: false,
//       seen: false,
//       isSuperUser: "0",
//       stateType: "ap",
//     };
//     this.socket = io("https://examsapi.navachaitanya.net/my-app-chat");
//   }

//   componentDidMount() {
//     const getUserStatus = async () => {
//       const uid = localStorage.getItem("num");
//       try {
//         const userDetails = await axios.post(
//           `${commonData["api"]}/get-user-details/${uid}`
//         );

//         localStorage.setItem("user", userDetails.data.result[0].su);

//         this.setState({ isSuperUser: String(userDetails.data.result[0].su) });
//       } catch (err) {}
//     };

//     getUserStatus();
//     this.socket.on("connect", () => {
//       const uid = localStorage.getItem("num");

//       console.log("Connected to Socket.IO server");
//       this.socket.emit(
//         "newUser",
//         "",
//         this.state.isSuperUser === "1" ? "Admin" : uid
//       );
//     });

//     this.socket.on("disconnect", () => {
//       console.log("Disconnected from Socket.IO server");
//     });
//     this.socket.on("message", (messageData, online) => {
//       const { messages, selectedChat } = this.state;
//       const { from } = messageData;
//       if (selectedChat === from) {
//         const newMessages = [...messages, messageData];
//         this.setState({ messages: newMessages });
//         this.scrollToBottom();
//       }
//     });

//     this.socket.on("online", (data) => {
//       console.log("dataonline", data);

//       this.setState({ isOnline: data ? true : false });
//     });

//     this.socket.on("getUserChat", (data) => {
//       const { selectedChat, isSelf, selectedChatName } = this.state;
//       this.setState(
//         {
//           messages: data[0],
//           isLoading: false,
//           selectedChatName:
//             selectedChat === "All"
//               ? "All Students"
//               : data[1][0].userName || selectedChatName,
//           isSelf: selectedChat === "All" ? false : isSelf,
//         },
//         this.scrollToBottom
//       );
//     });
//     this.socket.on("supportHomeAll", (data) => {
//       const uid = localStorage.getItem("num");
//       let newD = {
//         gid: uid,
//         groupName: "Chat With Admin",
//         self: true,
//         isgroup: false,
//         unread: 0,
//         stateType:"ap"
//       };

//       this.setState({
//         isLoading: false,
//         chatDetails:
//           this.state.isSuperUser !== "1" ? [newD, ...data[0]] : data[0],
//         tschatDetails: data[2],
//       });
//     });
//     this.socket.on("seen", (data) => {
//       console.log("dataonline", data);

//       this.setState({ seen: data ? true : false });
//     });
//     const { location } = this.props;
//     if (location.search.includes("?support=true")) {
//       this.setState(
//         {
//           isOpen: true,
//           isSelf: false,
//           isgroup: false,
//           selectedChat: null,
//           selectedChatName: null,
//         },
//         () => {
//           this.getData();
//           window.history.replaceState(
//             {},
//             document.title,
//             window.location.pathname
//           );
//           // this.checkForNewMessage();
//         }
//       );
//       // window.location=''
//       return;
//     }
//     if (location.pathname.includes("/support/user/")) {
//       let chat = location.pathname.split("/support/user/")[1];
//       this.setState(
//         {
//           isOpen: true,
//           isSelf: true,
//           isgroup: chat.length === 10 ? false : true,
//           selectedChat: chat,
//           selectedChatName: chat,
//         },
//         () => {
//           // this.getData();

//           this.checkForNewMessage();
//           // window.history.replaceState(
//           //   {},
//           //   document.title,
//           //   window.location.pathname
//           // );
//         }
//       );

//       return;
//     }

//     this.getData();

//     // this.getChatDataDefault();
//   }

//   getData = async () => {
//     // const token = Cookies.get("jwt_token");
//     // const headers = {
//     //   "Content-Type": "application/json",
//     //   authorization: token,
//     //   "Access-Control-Allow-Origin": "*",
//     // };
//     const uid = localStorage.getItem("num");

//     // const body = {
//     //   type: "supportHomeAll",
//     //   search: uid,
//     //   qid: 0,
//     // };

//     try {
//       this.setState({ isLoading: true });
//       console.log(commonData["api"]);
//       const data = await axios.post(`${commonData["api"]}/support`, body, {
//         headers,
//       });
//       if (data.data[0].length > 0) {
//         const audio = new Audio(audioFile);
//         // audio.play();
//       }
//       this.setState({
//         isLoading: false,
//         supportHomeData: data.data[0],
//       });
//       this.props.storeinfo({ supportHomeData: data.data[0] });
//     } catch (err) {
//       this.setState({ isLoading: false });
//     }
//   };
//   openWidget = () => {
//     this.setState({ isOpen: true });
//     document.body.classList.add("chat-widget-active");
//     clearInterval(this.countdown);
//   };

//   closeWidget = () => {
//     const { location } = this.props;

//     // clearInterval(this.countdown);
//     this.setState({
//       isOpen: false,
//       selectedChat: null,
//       isSelf: false,
//       isgroup: false,
//       selectedChatName: null,
//     });
//     document.body.classList.remove("chat-widget-active");
//     window.location.href = "/";
//   };

//   selectChat = (name, chatId, self, isgroup, count, stateType) => {
//      clearInterval(this.countdown);
//     const uid = localStorage.getItem("num");

//     this.socket.emit(
//       "online",
//       this.state.isSuperUser === "1" ? "Admin" : uid,
//       chatId
//     );
//     this.setState(
//       {
//         selectedChatName: name,
//         selectedChat: chatId,
//         isSelf: self,
//         isgroup: isgroup,
//         isLoading: true,
//         totalCount: count,
//         stateType,
//       },
//       () => this.checkForNewMessage()
//     );
//   };

//   sendMessage = async () => {
//     const { message, selectedChat, isgroup, stateType } = this.state;
//     if (message.trim() === "") return;
//     const uid = localStorage.getItem("num");
//     // this.socket.emit(
//     //   "online",
//     //   this.state.isSuperUser === "1" ? "Admin" : uid,
//     //   selectedChat
//     // );
//     this.socket.emit(
//       "newUser",
//       "",
//       this.state.isSuperUser === "1" ? "Admin" : uid
//     );
//     const newMessage = {
//       senttime: 1,
//       id: Math.floor(Math.random() * 1000), // Just a temporary ID generation
//       from: this.state.isSuperUser === "1" ? "Admin" : uid,
//       to: this.state.isSuperUser === "1" ? selectedChat : "Admin",
//       message: message,
//       type: "sent",
//     };

//     // if (isgroup) {
//     // sendPush({
//     //   title: "New Message - " + selectedChatName,
//     //   message: message,
//     //   // filters: [
//     //   //   { field: "tag", key: "number", relation: "=", value: String(uid) },
//     //   // ],
//     //   url: `https://exams.navachaitanya.net/support/user/${selectedChat}`,
//     // });
//     // } else {
//     //   if (this.state.isSuperUser === "1") {
//     //     sendPush({
//     //       title: "New Message - Admin",
//     //       message: message,
//     //       filters: [
//     //         { field: "tag", key: "number", relation: "=", value: String(uid) },
//     //       ],
//     //       url: `https://exams.navachaitanya.net/support/user/${selectedChat}`,
//     //     });
//     //   } else {
//     //     sendPush({
//     //       title: `New Message From Student...`,
//     //       message: `Message : ${message}\nFrom : ${uid}\n`,
//     //       filters: [
//     //         {
//     //           field: "tag",
//     //           key: "user",
//     //           relation: "=",
//     //           value: "admin",
//     //         },
//     //       ],
//     //       url: `/support/user/${uid}`,
//     //     });
//     //   }
//     // }
//     const body = {
//       type: "sendUserMsg",
//       search: message,
//       qid: `${this.state.isSuperUser === "1" ? "Admin" : uid}_${
//         this.state.isSuperUser === "1" ? selectedChat : "Admin"
//       }_1_${isgroup ? 1 : 0}`,
//       stateType
//     };
//     // const token = Cookies.get("jwt_token");
//     // const headers = {
//     //   "Content-Type": "application/json",
//     //   authorization: token,
//     //   "Access-Control-Allow-Origin": "*",
//     // };
//     try {
//       // axios.post(`${commonData["api"]}/support`, body, {
//       //   headers,
//       // });
//       this.socket.emit("message", body);
//       this.setState(
//         (prevState) => ({
//           messages: [...prevState.messages, newMessage],
//           message: "",
//         }),
//         () => {
//           this.scrollToBottom();
//         }
//       );
//     } catch (error) {}
//   };

//   deleteMsg = async () => {
//     const { idDelete, messages } = this.state;
//     const token = Cookies.get("jwt_token");
//     const headers = {
//       "Content-Type": "application/json",
//       authorization: token,
//       "Access-Control-Allow-Origin": "*",
//     };
//     const body = {
//       type: "AdminMsgDelete",
//       search: "",
//       qid: idDelete,
//     };
//     try {
//       this.setState({ isLoading: true });

//       const data = await axios.post(`${commonData["api"]}/support`, body, {
//         headers,
//       });
//       console.log(data.data[0]);
//       this.setState({
//         isLoading: false,
//         homeAutoData: data.data[0],
//       });
//       this.props.storeinfo({ homeAutoData: data.data[0] });
//     } catch (err) {
//       NotificationManager.error(`Something Went Wrong`);
//       this.setState({ isLoading: false });
//     }
//   };

//   getChatData = async () => {
//     const { clickedId, searchInput } = this.state;
//     const token = Cookie.get("jwt_token");
//     const headers = {
//       "Content-Type": "application/json",
//       authorization: token,
//       "Access-Control-Allow-Origin": "*",
//     };
//     const body = {
//       type: "getChatData",
//       search: searchInput,
//       qid: clickedId,
//     };
//     try {
//       this.setState({ isLoading: true, searchClicked: true });

//       const data = await axios.post(`${commonData["api"]}/support`, body, {
//         headers,
//       });
//       console.log(data.data[0]);
//       this.setState({
//         isLoading: false,
//         autoRespondData: data.data[0],
//       });
//     } catch (err) {
//       NotificationManager.error(`Something Went Wrong`);
//       this.setState({ isLoading: false });
//     }
//   };
//   renderMessageSelected = () => {
//     const { autoMsgData } = this.state;
//     return (
//       <>
//         <p className="messagesauto sent">{autoMsgData.recieve}</p>

//         <p className="messagesauto msgrecived">
//           <span
//             dangerouslySetInnerHTML={{
//               __html: autoMsgData.reply + "<br/><br/>",
//             }}
//           ></span>
//         </p>

//         <p
//           className="messagesauto startagain"
//           onClick={() =>
//             this.setState({
//               showContent: true,
//               autoMsgSelected: false,
//               searchClicked: false,
//               autoMsgData: [],
//               searchInput: "",
//               autoRespondData: [],
//             })
//           }
//         >
//           Search Again
//         </p>
//       </>
//     );
//   };
//   sendMsg = async () => {
//     const { searchInput } = this.state;
//     if (searchInput === "") {
//       NotificationManager.error(`Please enter search input`);
//     } else {
//       try {
//         const data = await axios.post(
//           `${commonData["api"]}/admin/qbankdata`,
//           body,
//           { headers }
//         );
//         // console.log(CouponData);
//         this.setState(
//           {
//             messages: messages.filter((e) => e.id != idDelete),
//           },
//           NotificationManager.success(`Message Deleted Succesfully...`)
//         );
//       } catch (err) {
//         NotificationManager.error(`Something Went Wrong`);
//       }
//     }
//   };
//   deleteAllMsgs = async () => {
//     const { idDelete, chatDetails } = this.state;
//     const token = Cookies.get("jwt_token");
//     const headers = {
//       "Content-Type": "application/json",
//       authorization: token,
//       "Access-Control-Allow-Origin": "*",
//     };
//     const body = {
//       type: "AdminMsgAllDelete",
//       search: "",
//       qid: idDelete,
//     };
//     if (window.confirm("Do you really want to remove this Message ?")) {
//       try {
//         const data = await axios.post(
//           `${commonData["api"]}/admin/qbankdata`,
//           body,
//           { headers }
//         );
//         // console.log(CouponData);
//         this.setState(
//           {
//             chatDetails: chatDetails.filter((e) => e.gid != idDelete),
//           },
//           NotificationManager.success(`Message Deleted Succesfully...`)
//         );
//       } catch (err) {
//         NotificationManager.error(`Something Went Wrong`);
//       }
//     }
//   };

//   scrollToBottom = () => {
//     try {
//       const element = document.getElementById("messageend");
//       if (element) element.scrollIntoView();
//     } catch (error) {}
//     clearInterval(this.countdown);
//     setTimeout(() => {
//       this.timer();
//     }, 0);
//   };
//   renderPopUp = () => {
//     const {
//       popUpOpen,
//       showContent,
//       supportHomeData,
//       title,
//       searchClicked,
//       autoMsgSelected,
//       clickedId,
//       userNum,
//     } = this.state;
//     const { location, history } = this.props;
//     const name = localStorage.getItem("name");
//     const token = Cookie.get("jwt_token");
//     return (
//       <Dialog
//         open={popUpOpen}
//         onClose={this.handleOpen}
//         maxWidth={"sm"}
//         // fullWidth
//         fullScreen
//       >
//         {" "}
//         <script>{`console.log(Android.getUserDetails(),'window.222')`}</script>
//         {!showContent ? (
//           <DialogTitle id="alert-dialog-title" className="supportdailog">
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 color: "#FFFF",
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                 }}
//               >
//                 Hi {name}
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     marginLeft: 10,
//                   }}
//                 >
//                   <SentimentSatisfiedAltIcon />
//                 </div>
//               </div>
//               <div className="closebtn">
//                 <Button onClick={this.handleOpen} style={{ color: "#fff" }}>
//                   X
//                 </Button>
//               </div>
//             </div>
//           </DialogTitle>
//         ) : (
//           <DialogTitle id="alert-dialog-title" className="supportdailog">
//             <div
//               style={{
//                 display: "flex",
//                 color: "#FFF",
//                 justifyContent: "space-between",
//               }}
//             >
//               {/* {!autoMsgSelected && (
//                 <div
//                   onClick={() =>
//                     this.setState((p) => ({
//                       showContent: !p.showContent,
//                       autoMsgSelected: false,
//                       autoMsgData: [],
//                     }))
//                   }
//                   style={{ cursor: "pointer" }}
//                 >
//                   <p style={{ fontSize: 28, backgroundColor: "transparent" }}>
//                     🔙
//                   </p>{" "}
//                 </div>
//               )} */}
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                 }}
//               >
//                 <h5 style={{ textAlign: "center", marginLeft: 15 }}>{title}</h5>
//               </div>
//               <div className="closebtn">
//                 {showContent && clickedId === 1 && (
//                   <Button
//                     onClick={
//                       searchClicked || autoMsgSelected
//                         ? this.handleSearchClose
//                         : this.handleOpen
//                     }
//                     style={{ color: "#fff" }}
//                   >
//                     X
//                   </Button>
//                 )}
//                 {showContent && clickedId === 2 && (
//                   <Button
//                     onClick={
//                       location.pathname.includes("/support/user/")
//                         ? history.replace("/")
//                         : this.CloseUserChat
//                     }
//                     style={{ color: "#fff" }}
//                   >
//                     X
//                   </Button>
//                 )}
//               </div>
//             </div>
//           </DialogTitle>
//         )}
//         <DialogContent className="dailogContent2" style={{ display: "flex" }}>
//           {!showContent && (
//             <div className="sidebar-chat">
//               {supportHomeData.map((ee) => (
//                 <div key={"supporttitles" + ee.id}>
//                   {this.renderLeftData(ee)}
//                 </div>
//               ))}
//             </div>
//           )}
//           {showContent && clickedId === 1 && (
//             <div className="message-content">
//               <div className="chatData">
//                 {" "}
//                 {autoMsgSelected
//                   ? this.renderMessageSelected()
//                   : this.renderContent()}
//               </div>
//             </div>
//           )}
//           {showContent &&
//             token !== undefined &&
//             clickedId === 2 &&
//             (localStorage.getItem("user") === "0" ? (
//               <Chat />
//             ) : (
//               <AdminChat num={userNum} />
//             ))}
//         </DialogContent>
//       </Dialog>
//     );
//   };
//   render() {
//     const {
//       isOpen,
//       selectedChat,
//       selectedChatName,
//       message,
//       chatDetails,
//       messages,
//       isLoading,
//       isSelf,
//       tschatDetails,
//     } = this.state;
//     const phoneNum = localStorage.getItem("userDetailsApp");
//     const uid = localStorage.getItem("num");
//     return (
//       <>
//         {/* <div
//           className="download-banner"
//           style={{
//             display:
//               phoneNum ||
//               this.props.location.pathname.split("/")[1] === "attempt-exam"
//                 ? "none"
//                 : "",
//           }}
//         >
//           <a
//             href="https://play.google.com/store/apps/details?id=com.navachaitanya.onlineexams&hl=te&gl=US"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <span>
//               ఎగ్జామ్స్ మరింత సులభంగా రాయడానికి NC Exams App ను
//               డౌన్లోడ్ చేసుకోండి
//             </span>
//             <img
//               src="/favicon.png"
//               alt="Download App"
//               style={{
//                 width: 20,
//                 height: 20,
//                 marginTop: 10,
//                 marginLeft: 10,
//                 marginRight: 10,
//               }}
//             />
//             Click Here
//           </a>
//         </div> */}

//         <div className={`chat-widget-container  ${isOpen ? "active" : ""} `}>
//           {/* <button className="chat-widget-btn" onClick={this.openWidget}>
//           Chat
//         </button> */}
//           {/* <div
//           className="support"
//           onClick={this.openWidget}
//           style={{
//             display:
//               this.props.location.pathname.split("/")[1] === "attempt-exam" &&
//               "none",
//           }}
//         >
//           {/* <ForumIcon
//             className="home-button marg"
//             style={{ color: "white", margin: "auto" }}
//           />{" "} 
//           HelpLine
//         </div> */}
//           {isOpen && (
//             <div className="chat-widget">
//               {!selectedChat ? (
//                 <div>
//                   <div
//                     className="header"
//                     style={{ paddingLeft: 20, paddingRight: 20 }}
//                   >
//                     <h2 className="title">Select a Chat</h2>
//                     <button className="close-btn" onClick={this.closeWidget}>
//                       Close
//                     </button>{" "}
//                   </div>
//                   <ul
//                     className="chat-list"
//                     style={{
//                       padding: 5,
//                     }}
//                   >
//                     {chatDetails.map((msg, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           border: msg.self ? "2px solid green" : "",
//                           padding: 0,
//                         }}
//                       >
//                         <span
//                           onClick={() =>
//                             this.selectChat(
//                               msg.groupName,
//                               msg.gid,
//                               msg.self,
//                               msg.isgroup,
//                               msg.unread,
//                               "ap"
//                             )
//                           }
//                           style={{
//                             paddingLeft: 15,
//                             paddingRight: 15,
//                             width: msg.isgroup === 1 ? "100%" : "90%",
//                             background:
//                               msg.unread > 0 && msg.isgroup === 0
//                                 ? "gold"
//                                 : "white",
//                             fontSize: 15,
//                             color:
//                               msg.seenByUser === 0 ||
//                               this.state.isSuperUser !== "1"
//                                 ? "black"
//                                 : "red",
//                           }}
//                         >
//                           {msg.groupName}{" "}
//                           {msg.unread <= 0 || msg.isgroup === 1 ? null : (
//                             <span>({msg.unread})</span>
//                           )}
//                           {/* {msg.isgroup === 1 ? null : (
//                             <span
//                               style={{
//                                 fontSize: 10,
//                                 color: msg.seenByUser === 0 ? "black" : "red",
//                               }}
//                             >
//                               {msg.seenByUser === 0 ? "seen" : "sent"}
//                             </span>
//                           )} */}
//                         </span>
//                         {this.state.isSuperUser === "1" &&
//                           (msg.isgroup === 0 ? (
//                             <i
//                               className="fas fa-trash"
//                               style={{
//                                 cursor: "pointer",
//                                 marginTop: 5,
//                                 color: "black",
//                                 textAlign: "right",
//                                 marginRight: 10,
//                                 marginLeft: 10,
//                               }}
//                               onClick={() =>
//                                 this.setState(
//                                   {
//                                     idDelete: msg.gid,
//                                   },
//                                   () => this.deleteAllMsgs()
//                                 )
//                               }
//                             ></i>
//                           ) : (
//                             "(" + msg.unread + ")"
//                           ))}
//                       </li>
//                     ))}
//                     {tschatDetails.length > 0 && (
//                       <p style={{ textAlign: "center", background: "gold" }}>
//                         Telangana Groups
//                       </p>
//                     )}
//                     {tschatDetails.map((msg, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           border: msg.self ? "2px solid green" : "",
//                           padding: 0,
//                         }}
//                       >
//                         <span
//                           onClick={() =>
//                             this.selectChat(
//                               msg.groupName,
//                               msg.gid,
//                               msg.self,
//                               msg.isgroup,
//                               msg.unread,
//                               "ts"
//                             )
//                           }
//                           style={{
//                             paddingLeft: 15,
//                             paddingRight: 15,
//                             width: msg.isgroup === 1 ? "100%" : "90%",
//                             background:
//                               msg.unread > 0 && msg.isgroup === 0
//                                 ? "gold"
//                                 : "white",
//                             fontSize: 15,
//                             color:
//                               msg.seenByUser === 0 ||
//                               this.state.isSuperUser !== "1"
//                                 ? "black"
//                                 : "red",
//                           }}
//                         >
//                           {msg.groupName}{" "}
//                           {msg.unread <= 0 || msg.isgroup === 1 ? null : (
//                             <span>({msg.unread})</span>
//                           )}
//                           {/* {msg.isgroup === 1 ? null : (
//                             <span
//                               style={{
//                                 fontSize: 10,
//                                 color: msg.seenByUser === 0 ? "black" : "red",
//                               }}
//                             >
//                               {msg.seenByUser === 0 ? "seen" : "sent"}
//                             </span>
//                           )} */}
//                         </span>
//                         {this.state.isSuperUser === "1" &&
//                           (msg.isgroup === 0 ? (
//                             <i
//                               className="fas fa-trash"
//                               style={{
//                                 cursor: "pointer",
//                                 marginTop: 5,
//                                 color: "black",
//                                 textAlign: "right",
//                                 marginRight: 10,
//                                 marginLeft: 10,
//                               }}
//                               onClick={() =>
//                                 this.setState(
//                                   {
//                                     idDelete: msg.gid,
//                                   },
//                                   () => this.deleteAllMsgs()
//                                 )
//                               }
//                             ></i>
//                           ) : (
//                             "(" + msg.unread + ")"
//                           ))}
//                       </li>
//                     ))}
//                   </ul>
//                   {isLoading && <Loader />}
//                 </div>
//               ) : (
//                 <div className="chat-details">
//                   <div className="header fixed">
//                     <button
//                       className="back-btn"
//                       onClick={() => {
//                         this.setState(
//                           { selectedChat: null, messages: [] },
//                           this.getData
//                         );
//                         // this.getData();
//                       }}
//                     >
//                       Back
//                     </button>
//                     <div>
//                       <h4 className="title" style={{ fontSize: 14 }}>
//                         {selectedChatName}
//                         <br />
//                         {isSelf || this.state.isSuperUser !== "1" ? null : (
//                           <p style={{ color: "black" }}>
//                             Total Students ({this.state.totalCount})
//                           </p>
//                         )}
//                       </h4>
//                     </div>
//                     <button className="close-btn" onClick={this.closeWidget}>
//                       Close
//                     </button>
//                   </div>
//                   <div className="messages-container">
//                     {!isLoading &&
//                       messages.map((msg, index) => (
//                         <>
//                           <div
//                             key={index}
//                             className={`message   ${
//                               msg.type === "sent" ? "sent" : "received"
//                             }`}
//                           >
//                             <span>
//                               {" "}
//                               {msg.type === "sent"
//                                 ? "You:"
//                                 : this.state.isSuperUser === "1"
//                                 ? msg.from
//                                 : "From Admin:"}
//                             </span>
//                             {this.state.isSuperUser === "1" && (
//                               <i
//                                 className="fas fa-trash"
//                                 style={{
//                                   cursor: "pointer",
//                                   marginTop: 5,
//                                   color: "black",
//                                   textAlign: "right",
//                                   marginLeft: 40,
//                                 }}
//                                 onClick={() =>
//                                   this.setState(
//                                     {
//                                       idDelete: msg.id,
//                                     },
//                                     () => this.deleteMsg()
//                                   )
//                                 }
//                               ></i>
//                             )}
//                             {msg.type === "sent" &&
//                             isSelf &&
//                             this.state.isSuperUser === "1" ? (
//                               <span
//                                 style={{
//                                   border: "1px solid red",
//                                   marginLeft: 20,
//                                   padding: 3,
//                                   borderRadius: 100,
//                                   color: "black",
//                                 }}
//                               >
//                                 {this.state.seen || msg.seen === "1"
//                                   ? "seen"
//                                   : "sent"}
//                               </span>
//                             ) : null}
//                             <p
//                               dangerouslySetInnerHTML={{
//                                 __html: this.renderMessageWithLinks(
//                                   msg.message
//                                 ),
//                               }}
//                               style={{ width: "auto" }}
//                             ></p>
//                           </div>
//                         </>
//                       ))}
//                     {isLoading && <Loader />}
//                     <div
//                       id="messageend"
//                       style={{ float: "left", clear: "both" }}
//                     ></div>
//                   </div>

//                   {isSelf || this.state.isSuperUser === "1" ? (
//                     <div className="message-input-container fixed-bottom">
//                       {isSelf ? (
//                         <h6 style={{ marginTop: 10 }}>
//                           {this.state.isOnline ? "online" : "away"}
//                         </h6>
//                       ) : null}
//                       <input
//                         className="message-input"
//                         type="text"
//                         value={message}
//                         onChange={(e) =>
//                           this.setState({ message: e.target.value })
//                         }
//                         placeholder="Type your message..."
//                       />

//                       <button className="send-btn" onClick={this.sendMessage}>
//                         Send
//                       </button>
//                     </div>
//                   ) : (
//                     <button
//                       className="chat-button"
//                       onClick={() =>
//                         this.selectChat("Chat With Admin", uid, true, false, 0,"ap")
//                       }
//                     >
//                       Have a question? Click here to chat with admin
//                     </button>
//                   )}
//                 </div>
//               )}
//             </div>
//           )}
//           <div>
//             <NotificationContainer />
//           </div>
//         </div>
//       </>
//     );
//   }
// }
// const mapStateToProps = (state) => {
//   const { homeAutoData, supportHomeData } = state.common;
//   return { homeAutoData, supportHomeData };
// };

// const mapDispatchToProps = { storeinfo };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(Support));
